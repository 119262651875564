/* ==========Logine ========= */
* {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style: none;
  font-family: sans-serif;
}
a{text-decoration: none;}
.login-img {
  background:  url("../public/images/login.svg"),  url("../public/images/school-stuff-pattern-free-vector.jpg");

  /* background-repeat: no-repeat, no-repeat;
  background-position: 40% 20% 10% 10%;
*/
/* width: 100% ;*/
background-size: cover; 
/* height: 100vh; */
} 
.forme {
  width: 25%;
  margin: 0px auto;
  margin-top: 140px ;
  margin-bottom: 140px;

  background-color: #f7f7f7;
  padding: 30px;
  border-radius: 20px;
  border: 0.5px solid;
  border-color: rgb(169, 169, 169);

}

a {
  text-decoration: none;

}

.Sign-in a {
  color: #fff; 
}

.Sign-in {border: 1px solid ;
  border-color: #1538b0;
  padding: 7px;
  border-radius: 10px;
  background-color: #1538b0;
}
/* ==========Logine and ========= */
/* ==========Sign-in ============*/
.schools-img{background: url("../public/images/41834102.jpg");
width: 100%;
height: 45vh;
background-size: cover;}
.box-1{width: 80% ;
background-color: #fff;
border-radius: 10px;
padding: 10px;
}
.Samantha{color: #000; display: flex; box-sizing: border-box;}
.message{position: absolute;
top: -20px; 
right: 20px;}
.box-2{width: 95% ;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 5px 10px 15px #888888;
}
.bus{width: 40%;margin-top: 10px; }
  .Samant{width: 55%;}
  .containers{max-width: 900px ; margin: 0px auto;}
  /* ===========Profile================ */
  .fa-pen-to-square{top: 56%; right: 43%; z-index: 21;opacity: 0.2;cursor: pointer;
  }

.Profiles{background-color: #1538b0 !important; 
  padding-bottom: 5px;
width: 100%;}
.Personal{width: 40% ;
margin: 0px auto; background-color: #fff;
border-radius: 10px; }
/* ===========Profile and ================ */
  @media only screen and (max-width: 1424px) {
    .forme {
      width: 30%;}
          .schools-img{height: 70vh;}
          .Personal{width: 50%;}
          .fa-pen-to-square{top: 50%; right: 41%;}
      }

  @media only screen and (max-width: 1024px) {
.Samantha{flex-wrap: wrap;}
    .bus{width: 90%;}
    .Samant{width: 90%;}
      .schools-img{height: 60vh;}
      .forme {  margin-top: 100px ;
        margin-bottom: 100px;
        width: 35%;}
        .Personal{width: 60%;}
        .fa-pen-to-square{top: 50%; right: 38%;}
  }
  @media only screen and (max-width:900px) {
    .bus{width: 90%;}
    .Samant{width: 90%;}
    .Samantha{flex-wrap: wrap;}
    .schools-img{height: 70vh;}
    .forme {  margin-top: 80px ;
      margin-bottom: 80px;
      width: 50%;}
      .Personal{width: 70%;}
      .fa-pen-to-square{top: 50%; right: 36%;}
  }
  @media only screen and (max-width:768px) {
    .bus{width: 50%;}
    .Samant{width: 40%; float: right;}
    .Samantha{display: flex;}
    .schools-img{height: 80vh;}
    .forme {  margin-top: 50px ;
      margin-bottom: 50px;
      width: 60%;}
      .Personal{width: 77%;}
      .fa-pen-to-square{top: 50%; right: 34%;}
  }
  @media only screen and (max-width:425px){
    .forme {  margin-top: 30px ;
      margin-bottom: 30px;
      width: 80%;}
      .Personal{width: 88%;}
      .fa-pen-to-square{top: 50%; right: 29%;}
  
  }
  @media only screen and (max-width:375px){
    .forme {  margin-top: 20px ;
      margin-bottom: 20px;
      width: 100%;}
      .Personal{width: 98%;}
      .fa-pen-to-square{top: 50%; right: 25%;}
  
  }
 .codpan {
    width:100%;
    height:50vh;
    overflow:hidden;
    background-image: linear-gradient(-180deg, #419DEF 0%, #1C6599 100%);
    z-index: 9999; 
  }

/* ==========Sign-in and ============*/

/*============ Fees-Payment============== */
.Fees-Payment{background-color:#0F235E;
width: 40%;
padding: 7px;
border-radius: 30px;
margin: 0px auto;
}
.Unpaid a{color: #000;}
.Unpaid{background-color:#fff;
border: none;
  padding: 8px 20px;
  border-radius: 20px;
 }
 .paid a{color:#000;}
 /* .paid{  border: none; background-color: transparent; float: right;} */
 .paid{background-color:#fff;
  border: none; color: #000;
    padding: 8px 30px;
    border-radius: 20px; float: right;}
.rupee{ color: #76BD21;}
.bi-currency-rupee{font-weight:800; font-size: 18px;}
.Pay-Now{background-color: #76BD21;border: none; color: #fff;
  padding: 8px 30px;
  border-radius: 10px; }
  .Detail{background-color:#4262CF;
    border: none; color: #fff;
      padding: 8px 30px;
      border-radius: 8px;}
.Test-btn{
 background-color:#4262CF;
    border: none; color: #fff;
      padding: 8px 30px;
      border-radius: 8px; 
}





#menuToggle
{
  display: block;
  position: relative;
  top: 10px;
  left: 20px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  text-decoration: none;
  color: #fff;
  
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: tomato;
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #fff;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #fff;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  position: absolute;
  width: 250px;
  margin: -100px 0 0 -68px;
  padding: 50px;
  padding-top: 100px;
  height: 110vh;
  background-color:#1538b0;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  font-size: 18px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}
.nav-link:focus, .nav-link:hover{color: #ffff;}